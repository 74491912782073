import React from 'react';
import 'react-vertical-timeline-component/style.min.css';
import RecommendationElement from './Recommendation';

const RecommendationList = ({ data }) => {
  const list = data?.edges
    .map(experience =>
      <RecommendationElement key={experience.node?.id} element={experience.node}/>
    )

  if (list.length === 0){
    return <p>There is no recommendations to show.</p>
  }

  return (
    <ul className="features">
      { list }
    </ul>
  )
}

const Recommendation = ({ data }) => (
  <section id="three" className="wrapper style3 special">
    <div className="inner">
      <header className="major">
        <h2>WHAT PEOPLE SAY ABOUT GLENN O'GRADY</h2>
      </header>
      <RecommendationList data={data}/>
    </div>
  </section>
)


export default Recommendation;

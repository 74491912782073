import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Scroll from '../components/Scroll';
import pic1 from '../assets/images/pic01.jpg';
import pic2 from '../assets/images/pic02.jpg';
import pic3 from '../assets/images/pic03.jpg';
import config from '../../config';
import LatestPosts from '../components/LatestPosts';
import RecommendationList from '../components/RecommendationList';

const IndexPage = ({ data }) => (
  <Layout>
    <section id="banner">
      <div className="inner">
        <h2>{config.heading}</h2>
        <p>{config.subHeading}</p>
        <ul className="actions special">
          <li>
            <Scroll type="id" element="cta">
              <a href="/#" className="button primary">
                Contact
              </a>
            </Scroll>
          </li>
        </ul>
      </div>
      <Scroll type="id" element="one">
        <a href="#one" className="more">
          Learn More
        </a>
      </Scroll>
    </section>

    <section id="one" className="wrapper style1 special">
      <div className="inner">
        <header className="major">
          <h2>
            BUSINESS AND TEAMS
          </h2>
          <p>
            Glenn O’Grady is a business advisor, non-executive director and investor, who specialises in start-up businesses, scaling up and exit strategy. 
          </p>
          <p>
          Glenn helps companies of all sizes scale up their businesses and align intention across senior leadership to have a common business goal and understanding regarding direction. With 20+ years' experience working with household names from Hive to The MailOnline, and founding my own software solutions business GuideSmiths, I specialise in advising businesses from start-up to exit and all in between to enable them to reach their full potential.
          </p>
          <p>
          Glenn has extensive experience in applying analytical and commercial acumen to business strategy and planning and challenging clients with the right questions. It is important to effectively bring people back to the core of the business, without the cloud of emotion, to work out the changes that will make a real difference to efficiency, service and – ultimately – success.
          </p>
        </header>
        <ul className="icons major">
          <li>
            <span className="icon fa-chart-bar major style3">
              <span className="label">Business</span>
            </span>
          </li>
          <li>
            <span className="icon solid fa-users major style3">
              <span className="label">Teams</span>
            </span>
          </li>
        </ul>
      </div>
    </section>

    <section id="two" className="wrapper alt style2">
      <section className="spotlight">
        <div className="image">
          <img src={pic2} alt="" />
        </div>
        <div className="content">
          <h2>
            Business
          </h2>
          <p>
          A clear strategy, decision making, technical architecture, financial models, risk management and leadership are all among the key factors that determine which business will thrive and which will struggle.
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={pic3} alt="" />
        </div>
        <div className="content">
          <h2>
            Teams
          </h2>
          <p>
          It's all about your people and being able to retain them. Establishing a culture, a method and efficient collaboration channels are essential ingredients for high performing teams.
          </p>
        </div>
      </section>
    </section>

    {/* <LatestPosts data={data.blog} /> */}

    <RecommendationList data={data.recommendation} />

    <section id="cta" className="wrapper style4">
      <div className="inner contact">
        <header>
          <h2>Stay in touch</h2>
          <p>
          If you would like to get in touch, please do so via the Contact button or LinkedIn below.
          </p>
        </header>
        <ul className="actions stacked">
          <li>
            <a href="mailto:glenn.ogrady@polograd.biz" className="button fit primary">
              Contact
            </a>
          </li>
        </ul>
      </div>
    </section>
  </Layout>
);

export default IndexPage;

export const lastPostsQuery = graphql`
   {
    blog: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { type: { eq: "blog" } } }
      limit: 3
		) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
			      title
            description
            featuredImage
          }
        }
      }
    },
    recommendation: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { type: { eq: "recommendation" } } }
		) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
			      author
			      author_link
            description
          }
        }
      }
    }
  }
`

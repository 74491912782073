import React from 'react';

const RecommendationElement = ({ element }) => {
  const recommendation = element.frontmatter;
  return (
    <li className="icon fa-quote-right solid">
      <p>
        { recommendation?.description }
      </p>
      <a target="_blank" rel="noreferrer" href={recommendation?.author_link}>
        <u><h3>{recommendation?.author}</h3></u>
      </a>
    </li>
  );
}
export default RecommendationElement;
